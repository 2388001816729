

@media screen and (max-width: 1000px) {

    .button {
        display: none;
    }

    .media-buttons {
        display: block;
    }

    .container {
        max-width: 100%;
        width: 100%;
    }

    .topheader {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .offcanvasMedia,
    .offcanvas-col {
        display: inline-block;
    }

    .offcanvas-col {
        right: 0;
        text-align: right;
        width: 100%;
        position: absolute;
        padding-top: 10px;
    }

    .offcanvas-header {
        padding: 0px;
    }

    .logo-in-header {
        position: absolute;
        text-align: left;
        left: 0;
        margin: 10px;
    }

    .haeder {
        display: none;
    }
    
    .logo-in-header {
        width: 50%;
    }

    .bh {
        display: none;
    }

    .hero-img {
        margin-top: 100px;
    }

    .header-con {
        text-align: left;
        display: flex;
        flex-flow: column;
    }

    .hero {
        display: none;
    }

    .hero-media {
        display: block;
        flex-flow: column-reverse;
    }

    .hero-title {
        width: 95%;
        margin-top: 10px;
        margin: 0;
        padding: 20px;
        position: relative;
    }

    h1 {
        font-size: 30px;
    }

    .media-buttons {
        text-align: center;
    }

    .media-buttons a {
        margin: 5px;
        font-size: 15px;
    }

    h2 {
        font-weight: 500;
        font-size: 30px;
    }

    .text-box {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .text-box p {
        width: 100%;
    }
    
    .text-in-box {
        width: 100%;
    }

    .profile-img {
       text-align: center;
    }

    .profile-img img {
        width: 300px;
    }

    .cards .row {
        display: block;
    }

    .card-img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .card-text {
        padding: 20px;
        width: 100%;
        font-size: 15px;
    }

    .cards-group-headline p {
        width: 100%;
    }

    .cards-group-headline .container {
        max-width: 100%;
    }

    .cards-haedline {
        margin: 0px;
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .faq {
        margin: 0px;
    }

    .error h1 {
        font-size: 50px;
    }

    footer {
        margin-top: 200px;
        padding: 40px;
    }

    .team {
        margin-top: 100px;
    }

    .team .card {
        margin: 10px;
    }
    
    .team .card-img-top {
        height: 500px;
        top: 0;
        object-fit: cover;
    }

    .impressum .end {
        margin-top: 30px;
        text-align: left;
    }

    footer h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0px;
    }
    
    .team-cards .row {
        margin: 0;
    }

    .team-cards h1 {
        margin-top: 150px;
        font-size: 40px;
    }

    .text-page {
        margin-top: 130px;
    }
    
    .text-page h1{
        font-size: 40px;
    }

    .a {
        font-size: 20px;
    }

    .impressum .row {
        display: block;
    }

    .mt {
        margin-bottom: 50px;
    }

    .mf-middle {
        margin-top: 30px;
    }
}

@media screen and (max-width: 360px) {

    .media-buttons a {
        margin: 5px;
        font-size: 10px;
    }

    .profile-img img {
        width: 200px;
    }
}
 
  /* xs */
  @media (max-width: 575.98px) {

  }
  
  /* sm */
  @media (min-width: 576px) {

  }

  /* md */
  @media (min-width: 768px) {
    .imageGroup .ig-img {
        max-width: 230px;
    }
  }
  
  /* lg */
  @media (min-width: 992px) {
    .imageGroup .ig-img {
        max-width: 280px;
    }
  }
  
  /* xl */
  @media (min-width: 1200px) {
    .imageGroup .ig-img {
        margin: 30px;
    }
  }
  
  /* xxl */
  @media (min-width: 1400px) {
    .imageGroup .ig-img {
        margin: 30px;
    }
  }
  