body {
    margin: 0;
    background-color: var(--color-body);
    font-family: "Montserrat", sans-serif;
}

p {
    font-size: 20px;
    color: var(--color-primary-gray);
}

h1 {
    font-weight: 500;
    font-size: 50px;
    color: var(--color-primary-gray);
}

h3 {
    color: var(--color-primary-gray);
}

h2 {
    font-weight: 500;
    font-size: 50px;
    color: var(--color-primary-gray)
}

a {
    color: var(--color-primary-gray);
}

.a {
    color: var(--color-primary-gray);
    font-weight: 500;
}

.button .btn {
    margin: 20px;
}

.close-btn {
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.white {
    background-color: #fff;
}

.logo-in-header {
    width: 350px;
    z-index: 100;
    position: absolute;
}

.red-outline {
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
}

.btn {
    transition: 0.5s;
    font-weight: 500;
    border-radius: 10px;
}

.red-outline:hover {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary);
    color: white;
}

.red-inline {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary);
    color: white;
}

.red-inline:hover {
    border: 2px solid var(--color-primary);
    background-color: var(--color-body);
    color: var(--color-primary);
}

.offcanvasMedia, .offcanvas-col {
    display: none;
}

.offcanvasMedia {
    margin: 10px;   
    border-radius: 10px;
    background-color: var(--color-primary);
    border: 2px solid var(--color-primary);
    color: var(--color-body);
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.offcanvasMedia:hover {
    background-color: #00000000;
    border: 2px solid var(--color-primary);
}

.offcanvasMedia:focus {
    background-color: var(--color-primary);
    border: 2px solid var(--color-primary);
    color: var(--color-body);
}

.offcanvas-body a {
    margin: 20px;
    font-size: 20px;
}

.offcanvas-body {
    text-align: left;
}

.offcanvas-header {
    display: block;
}

.offcanvas-header {
    margin: 10px;
    border-radius: 10px;
    opacity: none;
}

.offcanvas-header button {
    margin-right: 10px;
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.header {
    width: 100%;
}

.header-a {
    margin: 20px;
    color: var(--color-primary-gray);
    text-decoration: none;
    transition: 0.5s;
    font-weight: 500;
}

.header-a:hover {
   text-decoration: underline;
}

.col {
    text-align: left;
}

.hero {
    max-width: 100%;
}

.hero {
    display: flex;
}

.hero-title {
    z-index: 100;
    position: absolute;
    width: 600px;
    color: var(--color-primary-gray);
    margin: 5%;
    margin-top: 130px;
}

.hero-img {
    position: relative;
    width: 100%;
    overflow: hidden; 
}

.hero-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #ffffffc8 40%, #9198e500 70%);
    z-index: 1;
}

.hero-img img {
    width: 100%;
    height: auto;
    display: block;
    z-index: 0;
}

.hero-media {
    display: none;
}

.hero-img-media img {
    width: 100%;
}

.media-buttons {
    display: none;
    margin: 15px;
}

.media-buttons a {
    font-size: 15px;
}

.text-box {
    justify-content: center;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.text-box p {
    width: 50%;
}

.text-in-box {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
}

.profile {
    margin-top: 50px;
    margin-bottom: 50px;
}

.profile-img {
    margin: 20px;
    text-align: left;
}

.profile-img img {
    width: 500px;
    border-radius: 0;
    border-bottom-right-radius: 67%;
    border-bottom-left-radius: 67%;
}

.button-profile {
    margin-top: 50px;
}

.cards {
    width: 100%;
}

.cards-haedline {
    /* margin: 100px; */
}

.cards-haedline h2 {
    font-size: 50px;
}

.cards-haedline p {
    width: 500px;
}

.row {
    width: 100%;
    --bs-gutter-x: 0px
}

.row-img-right {
    flex-flow: row-reverse;
}

.card-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card-text {
    padding: 50px;
    width: 100%;
    font-size: 23px;
}

.card-text h2 {
    font-size: 35px;
}

.cards-group-text {
    text-align: left;
    width: 100px;
}

.card {
    margin: 10px;
    border: 0px solid #000000;
    background-color: var(--color-primary-5);
    border-radius: 0;
}

.card .card-text {
    margin: 0px;
    padding: 0;
    font-size: 20px;
}

.CardsGroup .container {
    width: 100%;
}

.cards-group-headline p {
    width: 100%;
    padding: 10px;
}

.cards-group-headline h2 {
    text-align: center;
    margin-bottom: 50px;
}

.cards-group-headline {
    margin-top: 100px;
    margin-bottom: 100px;
    width: 100%;
}

.faq {
    margin: 100px;
}

.faq .container{
    display: flex;
    justify-content: center;
}

.error {
    margin-top: 200px;
}

.error h1 {
    font-size: 100px;
}

.jc-space-between, .jc-sb {
    display: flex;
    justify-content: space-between;
}

.accordion {
    width: 800px;
    border: 2px solid var(--color-primary);
    border-radius: 20px;
    --bs-accordion-bg: none;
    z-index: 11;
}

.accordion-body {
    font-size: 20px;
}

.accordion-button:not(.collapsed) {
    color: var(--color-primary);
    background-color: #fff;
}

.faq h1 {
    text-align: center;
    margin: 50px;
}

.accordion-header button {
    font-weight: 500;
    font-size: 20px;
}

.accordion-button {
    color: var(--color-primary)
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: var(--color-primary-2);
}

.accordion-body {
    text-align: left;
}

.accordion-button {
    width: 98%;
    margin: 5px;
    border-radius: 10px;
    color: var(--color-primary);
}

.accordion-button:not(.collapse)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c00011'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.team {
    justify-content: center;
    width: 100%;
}

.team .card {
    margin: 50px;
}

.team-cards .card-text {
    padding-bottom: 20px;
}

.team .card-img-top {
    height: 600px;
    top: 0;
    object-fit: cover;
}

.team-cards {
    margin-top: 100px;
}

.imageGroup {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.imageGroup .col {
    text-align: center;
}

.imageGroup .ig-img {
    margin-bottom: 50px;
    object-fit: cover;
    max-width: 500px;
    max-height: 200px;
}

.team-cards .cards-group-headline {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: left;
}

.team-cards .row {
    margin: 20px;
}

.team-cards .col {
    margin: 0px;
}

.team-cards h2 {
    font-size: 35px;
    font-weight: 500;
}

.impressum .row {
    display: flex;
    justify-content: space-between;
}

.impressum .end {
    text-align: right;
}

footer a {
    color: var(--color-primary);
    font-size: 20px;
}

.block-impressum h2 {
    font-weight: 500;
}

footer {
    margin-top: 200px;
    padding: 100px;
}

footer h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}

.text-page {
    margin-top: 100px;
}

.text-page h1 {
    text-align: center;
    margin-bottom: 50px;
}

.fm {
    margin-top: 30px;
}

.impressum-page h2, .datenschutz-page h2 {
    margin-top: 50px;
    font-size: 2rem;
}

.impressum-page h3 {
    font-size: 1.5rem;
    margin-top: 25px;
}

.impressum-page h4 {
    font-size: 1.4rem;
    margin-top: 30px;
}

.footer-image {
    /* margin: 1rem; */
    margin-left: 1rem;
}

.footer-image img {
    width: 150px;  
    border-radius: 50%;
}

.footer-images-content {
    display: flex;
    justify-content: right;
}