@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.min.css';
@import url(./main.css);
@import url(./media.css);

:root {
    --color-body: #ffff;
    --color-primary-1: #CBEDE7;
    --color-primary-2: #A8D2D7;
    --color-primary-3: #94C6B2;
    --color-primary:  #c00011;
    --color-primary-4: #FCF0F1;
    --color-primary-5: #d9ebec;
    --color-primary-font: black;
    --color-primary-gray: #4f4f4f;
}

.bg-p-1 {
    background-color: var(--color-primary-1);
}

.bg-p-2 {
    background-color: var(--color-primary-2);
}

.bg-p-3 {
    background-color: var(--color-primary-3);
}

.bg-p-4 {
    background-color: var(--color-primary-4);
}

.bg-p-5 {
    background-color: var(--color-primary-5);
}